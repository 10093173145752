import ISelect from '@core/select';

export const LANGUAGE: ISelect[] = [
  { value: 'en', label: 'English' },
  { value: 'vi', label: 'Tiếng việt' },
];

const CONFIG = {
  API_BASE_URL: process.env.API_BASE_URL || 'https://ravolution-api.dev.altasoftware.vn',
  APP_NAME: process.env.APP_NAME,
  SITEKEY: process.env.RECAPTCHA_KEY || '6LdgqZgfAAAAAJAePQ47T-QaUjhUM-A7mGs2RXMV',
  LOGIN_PAGE: '/#',
  SSO_PAGE: '/#',
  OTP_TIMEOUT: 180,
  S3_BASE_URL: process.env.S3_BASE_URL || 'https://file.dev-altamedia.com/',
  API_360_URL: process.env.API_360_URL || 'https://nutifood-360-image.dev-altamedia.com/',
  EndRegister: process.env.END_REGISTER || '2022-12-04T06:00:00Z',
};

export default CONFIG;
