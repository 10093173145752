export default {
  'server.notFound': 'Không tồn tại',
  'server.networkError': 'Lỗi mạng',
  'Server.AccountEmail.NotExists': 'Thong tin dang nhap khong dung',
  'Server.AddBeat.Success': 'Thêm thành công',
  'Server.DeleteBeat.Success': 'Xóa thành công',
  'Server.UpdateBeat.Success': 'Cập nhập thành công',
  'server.customer.phone.duplicated': 'Số điện thoại đã được sử dụng',
  'server.request.toomany': 'Quý khách đã nhập sai quá nhiều lần, vui lòng thử lại sau 2 phút',
};
