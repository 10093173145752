import React, { PropsWithChildren } from 'react';
import { Route } from 'react-router-dom';

import { IRouter } from '@routers/interface';
import Loading from '@shared/components/Loading';

interface IShowRouter {
  routers: IRouter[];
  MasterLayout?: React.FC<PropsWithChildren<any>>;
}

const ShowRouter = ({ routers }: IShowRouter) => {
  return routers.map((router: IRouter) => {
    const Page: any = router.loader;
    return (
      <Route
        key={router.path}
        path={router.path}
        element={
          <React.Suspense fallback={<Loading />}>
            <Page />
          </React.Suspense>
        }
      />
    );
  });
};

export default ShowRouter;
