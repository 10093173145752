import React from 'react';
import { Routes } from 'react-router-dom';
import { publicPage } from '../mainRouter';
import ShowRouter from './ShowRouter';

const PublicPage: React.FC = () => {
  return <Routes>{ShowRouter({ routers: publicPage })}</Routes>;
};

export default React.memo(PublicPage);
