import enUS from 'antd/lib/locale/en_US';

import common from './common';
import home from './home';

import server from './server';

export default {
  ...enUS,
  ...common,
  ...server,
  ...home,
};
