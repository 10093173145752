import viVN from 'antd/lib/locale/vi_VN';
import auth from './auth';
import common from './common';
import server from './server';

import Form from './form';
import home from './home';

export default {
  ...viVN,
  ...common,
  ...server,
  ...auth,
  ...home,
  Form,
};
