export default {
  'server.notFound': 'Not found',
  'server.networkError': 'Network error',
  'Server.AddBeat.Success': 'Adding success',
  'Server.DeleteBeat.Success': 'Deleting success',
  'Server.UpdateBeat.Success': 'Updating success',
  'server.customer.phone.duplicated': 'The phone number has been used',
  'server.request.toomany': 'You have entered too many times, please try again after 2 minutes',
  'Duplicate entry': 'The phone number has been used',
};
