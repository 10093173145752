export default {
  'home.ticket': 'ticket',
  'home.activision': 'ticket activation',
  'home.begin': 'Let\'s get started',
  'home.register': 'Register',
  'home.benefit': 'Benefits',
  'home.policy': 'Policy',
  'home.input': 'Please input your code',

  'home.btn': 'Active',
  'home.info': 'Information',
  'home.info.text':
    'We take your privacy very seriously and have taken measures to ensure your personal information remains secure.',
  'home.info.note':
    '* By activating the ticket, you consent to the use of the registered information for the Starverse membership registration (Starverse Membership Benefits).',
  'home.info.checkBox':
    'I agree to receive information and commercial offers from Ravolution Music Festival.',
  'home.info.fullName': 'Full name',
  'home.info.phoneNumber': 'Phone number',
  'home.info.email': 'Email',
  'home.info.age': 'Age',

  'home.your-code': 'Your Code',
  'home.your-code.text1':
    'Now that you have your ticket, make sure you activate it before entering the event to receive 10,000đ direct top up to your Ravolution Card and spend them on food & drinks at the venue!',
  'home.your-code.text1_1': 'Now that you have your ticket',
  'home.your-code.text2-1': 'Special extras from Heineken:',
  'home.your-code.text2-2':
    'A chance to win a meet & greet with DJ line-up at the RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE.',
  'home.your-code.text2-3':
    'A chance to win a bunch of unique goodies from Heineken (iPhone, T-shirt, USB, water bottle, etc.).',
  'home.your-code.text2-4': 'A limited chance to win a VIP upgrade.',
  'home.your-code.text3':
    'By registering your ticket, you\'ll be automatically entered into a draw for a exclusive merchandise that are signed by Top100DJs line-up from RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE and bunch of other goodies.',
  'home.your-code.text4':
    'Turn up the party with limited-edition prizes from Heineken: <a href="https://star-verse.vn" target="_blank">https://star-verse.vn</a>',
  'home.message.wrong': 'This code has already been redeemed by another account.',

  'home.policy.title': 'terms & condition',
  'home.policy.text1': 'Upon registration, you hereby confirm that you are above 18 years old.',
  'home.policy.text2': 'You hereby declare that the information provided is true and correct.',
  'home.policy.text3':
    '  Any collected personal data will only be used to provide services you have requested or require. We will not collect any personal data from you that we do not need.',
  'home.policy.text4':
    '  Any information you provide will not be used for other commercial purposes and will not be sold, rented, leased o r forwarded to any third party.',
  'home.policy.text5':
    ' Special rewards from Heineken can only be obtained through the Starverse app. Please download the app and log in to claim your rewards and benefits that come with your admission ticket.',

  'home.benefit.title': 'Benefit',
  'home.benefit.text-1':
    '10,000đ (ten thousand Vietnam Dong) direct top up to your Ravolution Card to spend on food & drinks at the venue.',
  'home.benefit.text-1-1': 'Update program information as soon as possible',
  'home.benefit.text-2':
    'Ravolution Card top-up and transaction information will be saved with your account and can be used at any future Ravolution Music Festival events.',
  'home.benefit.text-3-title': 'Special extras from Heineken.',
  'home.benefit.text-3-desc1':
    ' A chance to win a meet & greet with DJ line-up at the RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE.',
  'home.benefit.text-3-desc2':
    ' A chance to win a bunch of super awesome goodies from Heineken (T-shirt, iPhone, etc.)',
  'home.benefit.text-3-desc3': 'A limited chance to win a VIP upgrade.',
  'home.benefit.text-4':
    ' 01 (one) raffle ticket for the post-event Lucky Draw with a chance to win exclusive merchandises signed by Top100DJs line-up from RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE.',
  'home.benefit.title-rules': 'rules',
  'home.benefit.text-5':
    '  Registered ticket member will receive 01 (one) raffle code to join our Lucky Draw that is scheduled to be held 01 (one) week after the event of RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE (tentatively, Sunday 11th Dec 2022). More details: TBA on our official Facebook: Ravolution Music Festival.',
  'home.benefit.text-5-title': 'Prize list:',
  'home.benefit.text-5-desc1':
    '01 (one) T-shirt signed by every DJs from RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE',
  'home.benefit.text-5-desc2':
    '10 (ten) snapbacks signed by one random DJ from RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE',
  'home.benefit.text-6':
    'The Organization Board shall contact the winners via registered phone number/ email. Please ensure the accuracy of the information you provided on our Ticket Activation page.',
  'home.benefit.text-6-title':
    'Winners can redeem rewards directly at our event RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE that is going to take place in Ho Chi Minh City, Vietnam, on the 4th of December.',
  'home.address-HCM': '[Địa chỉ văn phòng Ravolution HCM]',
  'home.address-HN': '[Địa chỉ văn phòng Ravolution HN]',

  'modal.title': 'THANK YOU! YOU’RE ALL SET',
  'modal.desc1': 'Catch our upcoming shows and enjoy special promotions JUST FOR YOU',
  'modal.link': 'ravolution.asia',
  'modal.desc3': 'Download Starverse and claim your special treats from Heineken here:',
  'modal.desc4':
    '*Your Starverse login information has been created with the username as the email address and the password is your ticket code.',
  'home.RegExp': 'Invalid code',
  'home.info.pattern.phoneNumber': 'Invalid phone number',
  'customerBirthday.reject': 'Please do not register if you are under the age of 18',
  'home.p.end': 'Thanks your register',
};
