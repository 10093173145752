/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import store, { persistor } from '@core/store/redux';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container);

root.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>
);
serviceWorkerRegistration.register();
