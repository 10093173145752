import '@shared/assets/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import '@styles/styles.scss';

import { ConfigProvider } from 'antd';
// import lodash from 'lodash';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import locale from '@locale/index';
import { TokenSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import PublicPage from '@routers/component/PublicPage';


const App = () => {
  const { language } = useSelector(LanguageSelector);
  const memoLangData = React.useMemo(() => {
    return locale[language];
  }, [language]);

  return (
    <IntlProvider locale={language} messages={memoLangData}>
      <ConfigProvider locale={memoLangData}>
        <PublicPage />
      </ConfigProvider>
    </IntlProvider>
  );
};

export default App;
