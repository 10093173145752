export default {
  'home.btn': 'Đăng ký',
  'home.ticket': 'đăng ký',
  'home.activision': 'đăng ký vé',
  'home.begin': 'BẮT ĐẦU',
  'home.register': 'Đăng Ký',
  'home.benefit': 'Quyền lợi',
  'home.policy': 'Điều khoản',
  'home.input': 'Vui lòng nhập mã của bạn',

  'home.info': 'Thông tin',
  'home.info.text':
    'Chúng tôi tôn trọng quyền riêng tư của bạn và sẽ thực hiện các biện pháp bảo vệ thích hợp để bảo vệ thông tin cá nhân của bạn.',
  'home.info.note':
    '* Với việc đăng ký vé, bạn đồng ý rằng thông tin cung cấp cũng sẽ được dùng để đăng ký thành viên Starverse (Quyền lợi thành viên Starverse).',
  'home.info.checkBox':
    'Tôi đồng ý nhận email thông tin và quảng cáo từ Ravolution Music Festival.',
  'home.info.fullName': 'Họ và Tên',
  'home.info.phoneNumber': 'Số điện thoại',
  'home.info.email': 'Địa chỉ email',
  'home.info.age': 'Tuổi',

  'home.your-code': 'mã vé',
  'home.your-code.text1':
    'Bạn đã sở hữu tấm vé đến với Ravolution! Đăng ký thông tin ngay để nhận 10,000đ được nạp cho thẻ thanh toán của bạn tại ngày hội!',
  'home.your-code.text1_1':
    'Bạn đã sở hữu tấm vé đến với Ravolution! Đăng ký thông tin ngay để nhận thông tin của chương trình!',
  'home.your-code.text2-1': 'Quyền lợi đặc biệt từ Heineken:',
  'home.your-code.text2-2':
    'Cơ hội trở thành người may mắn được tham dự một buổi gặp gỡ thân mật (meet & greet) cùng dàn DJ line up của sự kiện RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE.',
  'home.your-code.text2-3':
    'Cơ hội nhận được nhiều phần quà hấp dẫn từ Heineken (iPhone, Áo thun, USB, Bình nước, v.v.).',
  'home.your-code.text2-4': 'Cơ hội giới hạn được nâng cấp hạng vé miễn phí lên VIP.',
  'home.your-code.text3':
    'Sau khi hoàn tất đăng ký, bạn cũng sẽ nhận được cơ hội dự thưởng bốc thăm may mắn với những vật phẩm đặc biệt với chữ ký độc quyền từ dàn DJ line-up tham gia sự kiện RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE.',
  'home.your-code.text4':
    'Refresh âm nhạc, bùng nổ chất riêng cùng hàng nghìn phần quà hấp dẫn từ Heineken: <a href="https://star-verse.vn" target="_blank">https://star-verse.vn</a>',

  'home.message.wrong': 'Mã vé trên đã được đăng ký bởi tài khoản khác.',

  'home.policy.title': 'điều khoản sử dụng',
  'home.policy.text1':
    'Đăng ký thông tin vé đồng nghĩa với việc bạn xác nhận rằng bạn 18 tuổi hoặc hơn.',
  'home.policy.text2': 'Bạn cam đoan những thông tin đã cung cấp là đúng và chính xác.',
  'home.policy.text3':
    'Mọi dữ liệu cá nhân chúng tôi thu thập sẽ chỉ được sử dụng để cung cấp các dịch vụ bạn đã yêu cầu hoặc yêu cầu. Chúng tôi chỉ thu thập những thông tin thực sự cần thiết để cung cấp dịch vụ.',
  'home.policy.text4':
    'Chúng tôi cam kết bảo vệ thông tin của bạn, không mua bán thông tin cá nhân của bạn cho các đơn vị khác vì các mục đích thương mại hay một mục đích nào khác không phù hợp với quy định của pháp luật.',
  'home.policy.text5':
    'Phần thưởng đặc biệt từ Heineken chỉ có thể được nhận qua ứng dụng Starverse. Vui lòng tải ứng dụng và đăng nhập để nhận thưởng và đảm bảo quyền lợi đi kèm vé vào cổng của bạn.',

  'home.benefit.title': 'quyền lợi',
  'home.benefit.text-1':
    'Nhận ngay 10,000đ (mười nghìn đồng) nạp trực tiếp cho Thẻ Thanh Toán của bạn tại sự kiện.',
  'home.benefit.text-1-1':
    'Cập nhật thông tin về chương trình sớm nhất',
  'home.benefit.text-2':
    'Thông tin giao dịch và top-up thẻ ở sự kiện Ravolution sẽ được lưu cùng tài khoản của bạn và có thể được sử dụng ở mọi sự kiện Ravolution Music Festival trong tương lai.',
  'home.benefit.text-3-title': 'Quyền lợi đặc biệt từ Heineken.',
  'home.benefit.text-3-desc1':
    'Cơ hội trở thành người may mắn được tham dự một buổi gặp gỡ thân mật (meet & greet) cùng dàn DJ line up của sự kiện RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE.',
  'home.benefit.text-3-desc2':
    'Cơ hội nhận được nhiều phần quà hấp dẫn từ Heineken (T-shirt, iPhone, v.v.).',
  'home.benefit.text-3-desc3': 'Cơ hội giới hạn được nâng cấp hạng vé miễn phí lên VIP.',
  'home.benefit.text-4':
    '01 (một) cơ hội tham gia quay thưởng may mắn với phần thưởng độc quyền từ dàn DJ line-up tham gia sự kiện RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE.',
  'home.benefit.title-rules': 'thể lệ',
  'home.benefit.text-5':
    'Một thành viên đăng ký đầy đủ thông tin sẽ tự động nhận được 01 mã dự thưởng cho chương trình Rút Thăm May Mắn được diễn ra 01 (một) tuần sau khi sự kiện RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE diễn ra (dự kiến Chủ Nhật, ngày 11/12/2022).Chi tiết sẽ được thông báo qua trang Facebook chính thức: Ravolution Music Festival.',
  'home.benefit.text-5-title': 'Cơ cấu giải thưởng:',
  'home.benefit.text-5-desc1':
    '01 (một) áo thun với đầy đủ chữ ký từ dàn DJ line-up tham gia sự kiệnRAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE',
  'home.benefit.text-5-desc2':
    '10 (mười) mũ thời trang với 01 (một) chữ ký của 01 (một) DJ ngẫu nhiên tham gia sự kiện RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE',
  'home.benefit.text-6':
    'Ban Tổ Chức sẽ liên hệ thành viên trúng thưởng qua số điện thoại và địa chỉ email đã đăng ký ở trang Đăng Ký Vé. Vui lòng đảm bảo thông tin bạn đã cung cấp là đúng và chính xác.',
  'home.benefit.text-6-title':
    'Thành viên may mắn trúng thưởng sẽ nhận quà trực tiếp tại sự kiện RAVOLUTION MUSIC FESTIVAL | 8TH EDITION | CHAPTER: UNIVERSAL LOVE diễn ra vào ngày 04/12/2022 tại Thành phố Hồ Chí Minh, Việt Nam.',
  'home.address-HCM': '[Địa chỉ văn phòng Ravolution HCM]',
  'home.address-HN': '[Địa chỉ văn phòng Ravolution HN]',

  'modal.title': 'CHÚC MỪNG BẠN ĐÃ ĐĂNG KÝ THÀNH CÔNG',
  'modal.desc1':
    'Đón chờ những sự kiện âm nhạc mới nhất cùng các ưu đãi đặc biệt DÀNH RIÊNG CHO BẠN!',
  'modal.desc2': 'Điều gì đang chờ đón bạn',
  'modal.link': 'ravolution.asia',
  'modal.desc3': 'Tải ngay Starverse và khám phá phần quà đặc biệt từ Heineken tại:',
  'modal.desc4':
    '*Thông tin đăng nhập tài khoản Starverse của bạn đã được khởi tạo với tên người dùng là địa chỉ email và mật khẩu là mã vé của bạn.',
  'home.RegExp': 'Mã code không hợp lệ',
  'home.info.pattern.phoneNumber': 'Số điện thoại không hợp lệ',
  'customerBirthday.reject': 'Vui lòng không đăng ký nếu bạn chưa đủ 18 tuổi',
  'home.p.end': 'Chương trình đăng ký đã kết thúc. Vui lòng quay trở lại sau',
};
